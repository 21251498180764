import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  data: {
    name: string;
    course_category_id: number;
    status: boolean;
    total_courses: null | number;
    total_subjects: null | number;
    course_category: {
        id: number;
        name: string;
    };
    web_icon: string;
    mobile_icon: string;
    class_content: null | string;
}[];
activeTab: number;
courses: {
  id: string;
  type: string;
  attributes: {
    id: string;
    bundle_course_thumbnail?:string;
    course_category:{
      name: string
    };
    rating: string;
    total_student?: string;
    bundle_name: string;
    course_thumbnail: string;
    status: boolean;
    course_child_category: {
      color: string;
      name: string;
    };
    first_year_price: number;
    course_name: string;
    is_wishlist: string;
    instructor_name: string;
    second_year_price: number;
    discounted_price: number;
    language_name?: string;
    is_purchased: boolean;
    short_description?: string;
    total_content?: string;
    lectures?: string;
    all_course_class_duration: number;
    student_count:number;
    is_cart: boolean;
    average_rating: {
      Average_rating: number;
      id: number;
    };
    course_class_count: string;
  }
}[];
hoveredIndex: number | null;
tabView: boolean;
listView: boolean;
pageNo: number;
per_page: number;
loading: boolean;
anchorEl: null | HTMLElement;
activeFilterTab: string;
activeOption: string | number | null;
filterOptions: Record<string, any>;
filters: Record<string, any>;
selectFilterName: Record<string, any>;
filterLoading: boolean;
courseNotFoundMsg: string;
serachCourseNotFountMsg: string;
isEducationCategories: boolean;
totalCourses: number;
courseLastPage: boolean;
resetFilter: boolean;
breadcrumb: Record<string, any>;
allCourseText: string;
isSearchResult: boolean;
courseListType: string;
showMoreLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      getName(MessageEnum.NavigationInfoPageMessage),
      getName(MessageEnum.NavigationScreenNameMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      data:[],
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      activeTab: 0,
      courses: [],
      hoveredIndex: -1,
      tabView: true,
      listView: false,
      pageNo: 1,
      per_page: 20,
      loading: false,
      anchorEl: null,
      activeFilterTab: 'Class',
      activeOption: null,
      filterLoading: false,
      filterOptions:{
        Class: [],
        Stream: [],
        Subject: [],
        Institute: [],
        Instructor: [],
        Language: [],
        Price: [{id:"1 - 1000",attributes: {name: "1 - 1000"}},{id:"1000 - 2000",attributes: {name: "1000 - 2000"}}, {id:"2000 - 5000",attributes: {name: "2000 - 5000"}}],
        Ratings: [{id:"ALL",attributes: {name: "ALL"}},{id:"5",attributes: {name: "5"}}, { id:"4",attributes: {name: "4"}}, {id:"3",attributes: {name: "3"}},{id:"2",attributes: {name: "2"}},{id:"1",attributes: {name: "1"}}],
      },
      filters: {
        class: '',
        stream: '',
        subject: '',
        institute_id: '',
        instructor_id: '',
        language_id: '',
        price: '',
        rating: '',
      },
      selectFilterName: {
        class: '',
        stream: '',
        subject: ''
      },
      courseNotFoundMsg: "Course Data not Found",
      serachCourseNotFountMsg: "",
      isEducationCategories: false,
      totalCourses: 1,
      courseLastPage: false,
      resetFilter: false,
      breadcrumb: [],
      allCourseText: "All Courses",
      isSearchResult: false,
      courseListType: "New Launched Courses",
      showMoreLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token, loading: true }, async () => {
        this.getCategories();
        this.getCoursesData(this.state.pageNo, this.state.per_page);
        this.getInstructorData();
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      
      this.handleApisResponse(apiRequestCallId, responseJson)
      if (responseJson.data) {
        this.isMessageApiCallId(apiRequestCallId, this.getCoursesApiCallId,responseJson);
        if (apiRequestCallId === this.getCategoriesApiCallId) {
          let array = responseJson.data;
          for (let i = 0; i < array.length; i++) {
            array[i].expand = false;
            array[i].Check = false;
          }
          this.setState({ categoriesArray: array });
        } else if (apiRequestCallId === this.addCategoryApiCallId) {
          this.setState({ isVisible: false, category: "" }, () => {
            this.getCategories();
          });
        } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
          this.setState(
            {
              isVisible: false,
              category: "",
              subCategory: "",
              selectedCategoryID: []
            },
            () => {
              this.getCategories();
            }
          );
        }
        //this.setState({ isVisible: false });
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId && !responseJson.error
      ) {
        this.getCategories();
      } 
      else if( apiRequestCallId === this.deleteCategoriesApiCallId && responseJson.error){
        this.parseApiCatchErrorResponse(responseJson.error.message);
      }
      else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId && !responseJson.error
      ) {
        this.getCategories();
      } 
      else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId && responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      }
      else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    this.receivedNavigationPayloadData(message);
    this.receiveSearchNavigationData(message);
    this.receiveScreenNameNavigationData(message);
    
    // Customizable Area End
  }

  // Customizable Area Start
  getCoursesApiCallId: string = "";
  getOurInstructionApiCallId: string = "";
  getInstitudeApiCallId: string = "";
  getSubjectApiCallId: string = "";
  getFilterlanguageAPICallId: string = "";
  getCategoryApiCallId: string = "";
  getStreamApiCallId: string = "";
  cousesFilterApiCallId: string = "";
  searchCoursesApiCallId: string = "";

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
    localStorage.removeItem("education_filter")
  }

  handleOutsideClick = (event: MouseEvent) => {
    const { anchorEl } = this.state;
    if (anchorEl && !anchorEl.contains(event.target as Node) && !(event.target as HTMLElement).closest('.rating-wrapper')) {
      this.handleClose();
    }
  };
  receivedNavigationPayloadData = (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.SessionResponseData));
      if (data) {
        this.setState({isEducationCategories: true, filters: {
          class:data.classId,
          stream: data.streamId,
          subject: data.subjectId 
        },
        selectFilterName: data }, () => {
            localStorage.setItem("education_filter", JSON.stringify(data))
            this.updateBreadcrumb(data)
            this.handleCourseFilterData();
          });
      }
    }
  }
  receiveSearchNavigationData = (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.NavigationInfoPageMessage));
      if(data) {
        this.setState({isSearchResult: true, allCourseText: "Search Courses" },
          () => {
            this.handleSearchCourses(data.searchParams);
          }
        )
      }
    }
  }
  receiveScreenNameNavigationData = (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.NavigationScreenNameMessage));
      if (data) {
        localStorage.setItem("courseListType",data.screenName)
        this.setState({allCourseText: data.screenName, courseListType: data.screenName, loading: true }, () => {
          this.getCoursesData(this.state.pageNo, this.state.per_page);
        })
      }
    }
  }
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {

    if (!this.state.token) {
      return;
    }
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callApi = (payload: any, runEngine: any) => {
    const { contentType, method, endPoint, body, headers } = payload;
    const header = {
        "Content-Type": contentType,
        ...headers
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}

  getCoursesData = (pageNo: number, per_page: number) => {
    if(this.state.courseListType!='search'){
    const {token} = this.state;
    let headers = {}
    if(token) {
      headers = {
        token
      }
    }
    let courseListEndPoint = configJSON.coursesListApiEndpoint;
    switch(this.state.courseListType) {
      case "Trending Courses":
        courseListEndPoint = configJSON.getTrendingCoursesWebApiEndPoint
        break;
      case "New Launched Courses":
        courseListEndPoint = configJSON.coursesListApiEndpoint
        break;
      case "Bundle Courses": 
        courseListEndPoint = configJSON.bundleCoursesWebApiEndPoint;
        break;
      default:
        break;
    }
    this.getCoursesApiCallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: courseListEndPoint + `?page=${pageNo}&per_page=${per_page}`,
      headers: headers
  }, runEngine)
}
  };

  getInstructorData = () => {
    this.setState({ filterLoading: true });
    this.getCategoryApiCallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getCategoryApiEndPoint,
      headers: {
      },
    }, runEngine);

    this.getOurInstructionApiCallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getOurInstructionWebApiEndPoint,
      headers: {
      },
    }, runEngine);
    this.getInstitudeApiCallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getInstitutesAPIEndPoint,
      headers: {
      },
    }, runEngine);

    this.getFilterlanguageAPICallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getFilterlanguageAPIlist,
      headers: {
      },
    }, runEngine);
  }

  getCategriesSteamData = (id: string) => {
    this.getStreamApiCallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getEducationSubCategoryApiEndPoint + `?course_category_id=${id}`,
      headers: {
      },
    }, runEngine);
  }

  getCategoriesSubjectData = (categoryId: string, subCategoryId: string) => {
    this.getSubjectApiCallId = this.callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.SubCategoryAPiEndPoint + `?page=1&per_page=20&course_category_id=${categoryId}&course_sub_category_id=${subCategoryId}`,
      headers: {
      },
    }, runEngine);
  }
  handleOptionClicks = (option: any, courseName: string = "") => {
    const { activeFilterTab, filters } = this.state;
    const filterKey = this.getFilterKey(activeFilterTab);
    const updatedFilter = { [filterKey]: filters[filterKey] === option ? '' : option };
  
    if (activeFilterTab === "Class") {
      this.setState({selectFilterName: {...this.state.selectFilterName, class: courseName,stream: "", subject :""}})
      filters[filterKey] === option ? this.setState({ filterOptions: { ...this.state.filterOptions, Stream: [] } }) : this.getCategriesSteamData(option)
    } else if (activeFilterTab === "Stream") {
      this.setState({selectFilterName: {...this.state.selectFilterName, stream: courseName, subject :"" }})
      filters[filterKey] === option ? this.setState({ filterOptions: { ...this.state.filterOptions, Subject : [] } }) : this.getCategoriesSubjectData(filters.class, option)
    } else if(activeFilterTab === "Subject") {
        this.setState({selectFilterName: {...this.state.selectFilterName, subject: courseName}})
    }
  
    this.setState({
      filters: { ...filters, ...updatedFilter },
    });
  };
  handleResetFilter = () => {
    const { filters } = this.state;
    const hasNonEmptyField = Object.values(filters).some(value => value !== '');
    if (hasNonEmptyField) {
      this.setState({resetFilter: true,  filters: {
        class: '',
        stream: '',
        subject: '',
        institute_id: '',
        instructor_id: '',
        language_id: '',
        price: '',
        rating: '',
      },selectFilterName: {
        class: "",stream: "", subject: ""
      }, loading: true }, () => {
        this.updateBreadcrumb(this.state.selectFilterName)
        this.getCoursesData(1, this.state.per_page)
      })
    } else {
      this.setState({ anchorEl: null})
    }
  }

  getFilterKey = (tab: string) => {
    switch (tab) {
      case 'Class':
        return 'class';
      case 'Stream':
        return 'stream';
      case 'Subject':
        return 'subject';
      case 'Institute':
        return 'institute_id';
      case 'Instructor':
        return 'instructor_id';
      case 'Language':
        return 'language_id';
      case 'Price':
        return 'price';
      case 'Ratings':
        return 'rating';
      default:
        return '';
    }
  };

  handleCourseFilterData = () => {
    this.setState({ filterLoading: true})
    const { filters } = this.state;
    
    const params = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });
    const {token} = this.state;
    let headers = {}
    if(token) {
      headers = {
        token
      }
    }
    const hasNonEmptyField = Object.values(filters).some(value => value !== '');
    if (hasNonEmptyField) {
      this.setState({loading : true ,anchorEl: null});
      this.cousesFilterApiCallId = this.callApi({
        contentType: configJSON.categoryApiContentType,
        method: configJSON.httpGetType,
        endPoint: configJSON.coursesFilterApiEndPoint + `?${params.toString()}`,
        headers,
      }, runEngine);
    } else {
      this.setState({ anchorEl: null })
    }
  }

  handleApisResponse = (apiRequestCallId: string, responseJson: any) => {
    if(!responseJson.errors) {
      switch(apiRequestCallId) {
        case this.getOurInstructionApiCallId:
          this.setState({ filterOptions: { ...this.state.filterOptions, Instructor: responseJson.data},filterLoading: false  });
          break;
        case this.getInstitudeApiCallId:
          this.setState({ filterOptions: { ...this.state.filterOptions, Institute: responseJson.data},filterLoading: false  });
          break;
        case this.getSubjectApiCallId:
          this.setState({ filterOptions: { ...this.state.filterOptions, Subject: responseJson.data},filterLoading: false  });
          break;
        case this.getFilterlanguageAPICallId:
          this.setState({ filterOptions: { ...this.state.filterOptions, Language: responseJson.data } ,filterLoading: false});
          break;
        case this.getCategoryApiCallId:
          this.setState({ filterOptions: { ...this.state.filterOptions, Class: responseJson.data } }, () => {
            this.setState({ filterLoading: false})
          });
          break;
        case this.getStreamApiCallId:
          this.setState({ filterOptions: { ...this.state.filterOptions, Stream: responseJson.data } });
          break;
        case this.searchCoursesApiCallId:
          this.handleFilterCoursesListResponse(responseJson);
          break;
        case this.cousesFilterApiCallId:
            this.handleFilterCoursesListResponse(responseJson);
          break;
        default:
          break;
      }
    } else {
      this.parseErrors(responseJson)
    }
  }
  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleSearchCourses = (search_params: string) => {
    this.setState({loading: true }) 
    const {token} = this.state;
    let headers = {}
    if(token) {
      headers = {
        token
      }
    }
    this.searchCoursesApiCallId = this.callApi({
      contentype: configJSON.categoryApiContentType,
      method: 'GET',
      endPoint : configJSON.searchCoursesApiEndPoint + `?courses[search_params]=${search_params}`,
      headers
    }, runEngine)
  }

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  isMessageApiCallId = (apiCallId: string, currentApiCallId: string,response: any) => {
    if(apiCallId === currentApiCallId){
      this.handleGetCoursesListResponse(response)
    }
  }

  handleGetCoursesListResponse = (res: {
    data : {
      id: string;
      type: string;
      attributes: {
        status: boolean;
        bundle_course_thumbnail?:string;
        id: string;
    course_category:{
      name: string
    };
    rating: string;
    total_student?: string;
    bundle_name: string;
        course_thumbnail: string;
        course_child_category: {
          color: string;
          name: string;
        };
        is_wishlist: string;
        course_name: string;
        instructor_name: string;
        first_year_price: number;
        second_year_price: number;
        discounted_price: number;
        is_purchased: boolean;
        short_description?: string;
        language_name?: string;
        total_content?: string;
        lectures?: string;
        all_course_class_duration: number;
        is_cart: boolean;
        student_count:number;
        average_rating: {
          id: number;
          Average_rating: number;
        };
        course_class_count: string;
      }
    }[],
    meta:{
      total_course: number;
      total_trending_course: number;
      total_bundle_course: number;
      course: string;
    };
  }) => {
    const {resetFilter} = this.state;
    const total = res.meta.total_course || res.meta.total_trending_course || res.meta.total_bundle_course
    const totalPages = Math.ceil(total / this.state.per_page);
    if(["Filter course record not found.", "Record not found."].includes(res?.meta.course)) {
      this.setState({
        courses: [],
        loading: false,
        showMoreLoading: false
      });
    }
    if (res?.data) {
      this.setState({
        courses: resetFilter ? res.data : [...this.state.courses, ...res?.data],
        loading: false,
        totalCourses: totalPages,
        courseLastPage: totalPages <= this.state.pageNo,
        showMoreLoading: false
      });
    } else {
      this.setState({
        loading: false,
        showMoreLoading: false
      });
    }
  };

  handleFilterCoursesListResponse = (res: {
    courses: any;
    bundle_courses: any;
    data : {
      id: string;
      type: string;
      attributes: {
        bundle_course_thumbnail?: string;
        course_category: {
          name: string
        };
        id: string;
        rating: string;
        total_student?: string;
        bundle_name: string;
        course_thumbnail: string;
        status: boolean;
        course_child_category: {
          color: string;
          name: string;
        };
        is_wishlist: string;
        course_name: string;
        instructor_name: string;
        first_year_price: number;
        second_year_price: number;
        discounted_price: number;
        total_content?: string;
        is_cart: boolean;
        is_purchased: boolean;
        short_description?: string;
        language_name?: string;
        lectures?: string;
        all_course_class_duration: number;
        student_count:number;
        course_class_count: string;
        average_rating: {
          id: number;
          Average_rating: number;
        };
      }
    }[],
    meta:{
      course: string;
    };
  }) => {
    this.updateBreadcrumb(this.state.selectFilterName)
    if(["Record not found.", "Filter course record not found."].includes(res?.meta.course)) {
      if(this.state.isSearchResult) {
        this.setState({ 
          serachCourseNotFountMsg : res?.meta.course,
          courses: [],
          courseLastPage: true,
          loading: false,
          filterLoading: false
        })
       } else{
        this.setState({
          courseNotFoundMsg: res?.meta.course,
          courses: [],
          courseLastPage: true,
          loading: false,
          filterLoading: false
        });
       }
    }
    if (res.data) {
      this.setState({
        courses: res.data,
        isEducationCategories: false,
        courseLastPage: true,
        loading: false,
        filterLoading: false,
      });
    } else if (res.courses?.data || res.bundle_courses.data) {
      this.setState({
        courses: [
          ...(res.courses.data),
          ...(res.bundle_courses.data),
        ],
        isEducationCategories: false,
        courseLastPage: true,
        loading: false,
        filterLoading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleTabView = () => {
    this.setState({ listView: false, tabView: true });
  };

  handleListView = () => {
    this.setState({ tabView: false, listView: true });
  };
 
  handleShowMore = () => {
    const currentPage = this.state.pageNo + 1;
    this.setState({ pageNo: currentPage }, () => {
      const totalPages = Math.ceil(this.state.totalCourses);
      const isLastPage = currentPage + 1 > totalPages;
        this.setState({ courseLastPage : isLastPage, showMoreLoading: true, loading: true })
        this.getCoursesData(currentPage,this.state.per_page);
    });
  };
  handleClick = (event: any) => {
    this.setState({anchorEl: event.currentTarget === this.state.anchorEl ? null : event.currentTarget })
  };
  handleClose = () => {
    this.setState({ anchorEl: null })
  };
  handleTabClick = (tab: string) => {
    this.setState({activeFilterTab: tab, activeOption: null });
  };
  getListItemText = (option: any) => {
    const { activeFilterTab } = this.state;
    if (activeFilterTab === "Instructor") {
      return option?.attributes?.full_name;
    } else if (activeFilterTab === "Language") {
      return option?.attributes?.language_name;
    } else {
      return option?.attributes?.name;
    }
  }
  isConditionalRending = (value: boolean , condition1: React.ReactNode , condition2: React.ReactNode) => {
    return value ? condition1 : condition2
  }
  isAnyFilterSelected = (filters: Record<string, any>) => {
    return Object.values(filters).some(filter => filter !== '');
  };
  updateBreadcrumb = (filters:any) => {
    const breadcrumbArray = [];
  
    if (filters.class) breadcrumbArray.push(filters.class);
    if (filters.stream) breadcrumbArray.push(filters.stream);
    if (filters.subject) breadcrumbArray.push(filters.subject);
  
    this.setState({breadcrumb:breadcrumbArray});
  };
  // Customizable Area End
}
